// Components
import Card from '../MainCard/MainCard';
import CardImageContent from '../CardImageContent/CardImageContent';
import GetStartedCtaBanner from '../../GetStartedCtaBanner/GetStartedCtaBanner';
// Styles
import styles from './MainCardLayout.module.scss';

type CardData = {
  images: string[];
  title: string;
  description: string;
  buttonText: string;
  displayMode?: string;
  variant?: string;
  subVariant?: string;
  link: string;
};

type CardLayoutProps = {
  cards: CardData[];
};

const CardLayout = ({ cards }: CardLayoutProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.columnContainer}>
        {cards.map((card, index) => (
          <div key={index}>
            <Card
              title={card.title}
              description={card.description}
              buttonText={card.buttonText}
              variant={card.variant ? card.variant : 'secondary'}
              subVariant={card.subVariant ? card.subVariant : 'inline'}
              link={card.link}
            >
              <CardImageContent
                images={card.images}
                title={card.title}
                displayMode={card.displayMode || 'carousel'}
              />
            </Card>
          </div>
        ))}
      </div>
      <GetStartedCtaBanner />
    </div>
  );
};

export default CardLayout;
