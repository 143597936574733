import { useQuery } from '@apollo/client';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
// Api
import { GET_ALL_HOME_PAGE_DATA } from 'api/homePage/queries';
import {
  StreamStatus,
  StreamOrderBy,
  StreamFilterInput,
} from 'api/graphql-global-types';
// Types
import type {
  GetAllHomePageData,
  GetAllHomePageDataVariables,
} from 'api/homePage/types/GetAllHomePageData';
// Hooks
import { useRefetchOnUserLogIn } from 'hooks';
// Layouts
import Main from 'layouts/Main/Main';
// Components
import NoAnimationHomePage from './NoAnimationHomePage';
import DesktopHomePage from './DesktopHomePage';
// Helpers
import { getCardsData } from './helpers/SlideHelper';
// Styles
import styles from './HomePage.module.scss';

const HomePage = () => {
  const upcomingStreamsVariables: StreamFilterInput = {
    orderBy: StreamOrderBy.ScheduleDate,
    streamStatus: [
      StreamStatus.Scheduled,
      StreamStatus.Active,
      StreamStatus.Paused,
    ],
    limit: 3,
  };

  const { data, refetch } = useQuery<
    GetAllHomePageData,
    GetAllHomePageDataVariables
  >(GET_ALL_HOME_PAGE_DATA, {
    variables: {
      upcomingStreamsInput: {
        ...upcomingStreamsVariables,
        haveSponsor: true,
      },
      upcomingStreamsDuplicationInput: {
        ...upcomingStreamsVariables,
        haveSponsor: false,
      },
    },
  });

  useRefetchOnUserLogIn({ refetch });

  const getData = (field: string) =>
    data?.getHomePageData?.[`${field}`]
      ?.slice()
      ?.sort((a, b) => a?.orderIndex - b?.orderIndex) || [];

  const banners = getData('mainBanners');

  const cardsData = getCardsData();
  return (
    <div className={styles.mobileSafeArea}>
      <Main
        className={cn(
          isMobile ? styles.homePageWrapper : styles.animationHomePageWrapper
        )}
        backgroundColor="background-main"
        withoutSidesPadding
      >
        {!isMobile ? (
          <DesktopHomePage banners={banners} cardsData={cardsData} />
        ) : (
          <NoAnimationHomePage banners={banners} cardsData={cardsData} />
        )}
      </Main>
    </div>
  );
};

export default HomePage;
