import { useRef } from 'react';
import styles from './CardImageContent.module.scss';

type CardImageContentProps = {
  images: string[];
  title: string;
  displayMode: string;
};

const CardImageContent = ({
  images,
  title,
  displayMode,
}: CardImageContentProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  if (displayMode === 'roundedImages') {
    return (
      <div className={styles.roundedImagesContainer}>
        {images.map((img, idx) => (
          <div key={idx} className={styles.roundedImageWrapper}>
            <img
              src={img || '/placeholder.svg?height=150&width=150'}
              alt={`Rounded ${idx + 1}`}
              className={styles.roundedImage}
            />
          </div>
        ))}
      </div>
    );
  }

  const slideWidthPx = 150;
  const gapPx = 8;
  const numberOfImages = images.length;
  const setWidthPx = numberOfImages * slideWidthPx + numberOfImages * gapPx;
  const trackWidth = `${setWidthPx * 2}px`;
  const scrollDistance = `-${setWidthPx}px`;
  const containerWidth = `${4 * slideWidthPx + 3 * gapPx}px`;
  const carouselLoop = [...images, ...images];

  return (
    <div
      className={styles.carouselContainer}
      ref={scrollRef}
      style={{ width: containerWidth, height: `${slideWidthPx}px` }}
    >
      <div
        className={styles.carouselTrack}
        style={
          {
            width: trackWidth,
            '--scroll-distance': scrollDistance,
          } as React.CSSProperties
        }
      >
        {carouselLoop.map((img, idx) => (
          <div
            key={idx}
            className={styles.carouselSlide}
            style={{ flex: `0 0 ${slideWidthPx}px`, marginRight: `${gapPx}px` }}
          >
            <img
              src={img || '/placeholder.svg?height=150&width=150'}
              alt={`${title} ${idx + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardImageContent;
