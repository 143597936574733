import React from 'react';
// Hooks
import { useAppContext, useGetCurrUser } from 'hooks';
// Helpers
import { getEnvLink } from 'helpers/routes';
// UI3
import LinkButton from 'ui3/LinkButton/LinkButton';
import Button from 'ui3/Button/Button';
// Constants
import { STREAMS } from 'constants/routes';
// Styles
import styles from './GetStartedCtaBanner.module.scss';

interface GetStartedCtaBannerProps {
  title?: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

export const GetStartedCtaBanner = ({
  title = 'Join the Biggest Sports Community in the World',
  description = 'Build deeper connections with creators, explore fan communities, and\ncollaborate with top brands in a space designed to bring everyone closer.',
  buttonText = 'Get Started Now',
  onButtonClick,
}: GetStartedCtaBannerProps) => {
  const backgroundUrl = `${getEnvLink()}/images/home-page/bento-box/bg.png`;

  const { setLoginModalVisibility } = useAppContext();

  const { data } = useGetCurrUser();

  const handleOpenLoginModal = () => {
    setLoginModalVisibility(true);
  };
  const isLoggedIn = !!data?.me?.id;

  return (
    <div className={styles.parentContainer}>
      <div
        className={styles.wrapper}
        style={{
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className={styles.bannerContainer}>
          <div className={styles.content}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.description}>{description}</p>
          </div>
          {isLoggedIn ? (
            <LinkButton
              link={STREAMS}
              onClick={onButtonClick}
              className={styles.button}
            >
              {buttonText}
            </LinkButton>
          ) : (
            <Button onClick={handleOpenLoginModal}>{buttonText}</Button>
          )}

          <div className={styles.collageWrapper}>
            <img
              src={`${getEnvLink()}/images/home-page/bento-box/bentoMain.png`}
              alt="Creators and fans collage"
              className={styles.collageImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStartedCtaBanner;
