// Hooks
import { useAppContext, useGetCurrUser } from 'hooks';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Constants
import { STREAMS } from 'constants/routes';
// UI3
import LinkButton from 'ui3/LinkButton/LinkButton';
import Button from 'ui3/Button/Button';
// Styles
import styles from './ImageGridCarousel.module.scss';

interface ImageCarouselProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  imageCount: number;
  imageBasePath?: string;
  scrollSpeed?: number;
}

const ImageGridCarousel = ({
  title = 'BUILD YOUR LEGACY.',
  buttonText = 'Get Started Now',
  imageCount,
  imageBasePath = `${getEnvLink()}/images/home-page/`,
  scrollSpeed = 0.5,
}: ImageCarouselProps) => {
  const { setLoginModalVisibility } = useAppContext();

  const { data } = useGetCurrUser();

  const generateImagePaths = (imageCount: number) => {
    return Array.from(
      { length: imageCount },
      (_, i) => `${imageBasePath}/carousel/${i + 1}.png`
    );
  };

  const handleOpenLoginModal = () => {
    setLoginModalVisibility(true);
  };

  const imagePaths = generateImagePaths(imageCount);
  const totalImages = imagePaths.length;
  const isOdd = totalImages % 2 !== 0;

  const topRow = imagePaths.slice(0, Math.ceil(totalImages / 2));
  const bottomRow = imagePaths.slice(Math.ceil(totalImages / 2));

  if (isOdd) {
    if (topRow.length % 2 !== 0) {
      topRow.push(bottomRow[0]);
    } else if (bottomRow.length % 2 !== 0) {
      bottomRow.unshift(topRow[0]);
    }
  }

  const doubledTopRow = [...topRow, ...topRow];
  const doubledBottomRow = [...bottomRow, ...bottomRow];

  const animationDuration = (topRow.length * 2) / scrollSpeed;

  const isLoggedIn = !!data?.me?.id;

  return (
    <div className={styles.container}>
      <div className={styles.carouselWrapper}>
        <div className={styles.carouselContainer}>
          <div
            className={`${styles.imageRow} ${styles.scrollRight}`}
            style={{
              animationDuration: `${animationDuration}s`,
              animationTimingFunction: 'linear',
              animationIterationCount: 'infinite',
            }}
          >
            {doubledTopRow.map((image, index) => (
              <div key={`top-${index}`} className={styles.imageContainer}>
                <img
                  src={image || '/placeholder.svg'}
                  alt={`Athlete ${(index % topRow.length) + 1}`}
                  className={styles.image}
                />
              </div>
            ))}
          </div>

          <div
            className={`${styles.imageRow} ${styles.scrollLeft}`}
            style={{
              animationDuration: `${animationDuration}s`,
              animationTimingFunction: 'linear',
              animationIterationCount: 'infinite',
            }}
          >
            {doubledBottomRow.map((image, index) => (
              <div key={`bottom-${index}`} className={styles.imageContainer}>
                <img
                  src={image || '/placeholder.svg'}
                  alt={`Athlete ${
                    (index % bottomRow.length) + topRow.length + 1
                  }`}
                  className={styles.image}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.overlay}></div>
      <div className={styles.contentOverlay}>
        <h1 className={styles.title}>{title}</h1>
        {isLoggedIn ? (
          <LinkButton link={STREAMS}>{buttonText}</LinkButton>
        ) : (
          <Button onClick={handleOpenLoginModal}>{buttonText}</Button>
        )}
      </div>
    </div>
  );
};

export default ImageGridCarousel;
