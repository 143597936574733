import type React from 'react';
// Ui3
import { ButtonVariant } from 'ui3/Button/Button';
import LinkButton from 'ui3/LinkButton/LinkButton';
// Styles
import styles from './MainCard.module.scss';

type CardProps = {
  title: string;
  description: string;
  buttonText: string;
  children: React.ReactNode;
  variant?: string;
  subVariant?: string;
  link: string;
};

const Card = ({
  title,
  description,
  buttonText,
  children,
  variant,
  subVariant,
  link,
}: CardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.imageContainer}>{children}</div>
      <div className={styles.textContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
      <LinkButton
        link={link}
        subVariant={subVariant as 'text' | 'outlined'}
        variant={variant as ButtonVariant}
      >
        {buttonText}
      </LinkButton>
    </div>
  );
};

export default Card;
