import React from 'react';
// Components
import Heroshot from './Heroshot/Heroshot';
import MainPageContentSection from './EventsCta/VideoBackgroundSection';
import DownloadAppSection from './GetAppCta/GetApp/DownloadAppSection';
import PhoneAppDownloadCta from './GetAppCta/GetPhonesCta/GetPhonesCta';
import ImageGridCarousel from './ImageGridCarousel/ImageGridCarousel';
import CardLayout from './MainCards/MainCardsLayout/MainCardsLayout';
// Layouts
import Footer from 'layouts/Footer/Footer';
// Helpers
import { getSlideContent } from './helpers/SlideHelper';

const NoAnimationHomePage = ({ banners, cardsData }) => {
  return (
    <div>
      <Heroshot banners={banners} />
      <CardLayout cards={cardsData} />
      <PhoneAppDownloadCta />
      <MainPageContentSection {...getSlideContent(1)} />
      <MainPageContentSection {...getSlideContent(2)} />
      <MainPageContentSection {...getSlideContent(3)} />
      <MainPageContentSection {...getSlideContent(4)} />
      <ImageGridCarousel imageCount={16} />
      <DownloadAppSection />
      <Footer />
    </div>
  );
};

export default NoAnimationHomePage;
