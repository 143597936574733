// Constants
import { ADVERTISE, CREATOR, EVENTS, INFO } from 'constants/external-links';
import { ATHLETES, MERCH, STREAMS } from 'constants/routes';
// Helpers
import { getEnvLink } from 'helpers/routes';

type SlideContent = {
  videoSrc?: string;
  title: string;
  description: string;
  overlayAlign: 'left' | 'right';
  image?: string;
  buttonLabel: string;
  link: string;
};

export const getCardsData = () => {
  const envLink = getEnvLink();

  return [
    {
      images: [
        `${envLink}/images/home-page/bento-box/memorabilia.png`,
        `${envLink}/images/home-page/bento-box/merch.png`,
        `${envLink}/images/home-page/bento-box/experience.png`,
        `${envLink}/images/home-page/bento-box/products.png`,
      ],
      title: 'Shop Authentic Products',
      description:
        "Athlete owned profiles. Shop your favorite athlete's products directly.",
      buttonText: 'Shop Now',
      displayMode: 'carousel',
      variant: 'primary',
      subVariant: 'text',
      link: MERCH,
    },
    {
      images: [
        `${envLink}/images/home-page/bento-box/rounded1.png`,
        `${envLink}/images/home-page/bento-box/rounded2.png`,
        `${envLink}/images/home-page/bento-box/rounded3.png`,
      ],
      title: 'Athletes & Sports Creators',
      description:
        'Follow your favorite talent, join streams, watch podcasts, and connect.',
      buttonText: 'View Talent',
      displayMode: 'roundedImages',
      variant: 'primary',
      subVariant: 'text',
      link: ATHLETES,
    },
    {
      images: [
        `${envLink}/images/home-page/bento-box/image-1.png`,
        `${envLink}/images/home-page/bento-box/image-2.png`,
        `${envLink}/images/home-page/bento-box/image-3.png`,
        `${envLink}/images/home-page/bento-box/image-4.png`,
        `${envLink}/images/home-page/bento-box/image-5.png`,
        `${envLink}/images/home-page/bento-box/image-6.png`,
      ],
      title: 'Watch Live Streams & PPVs',
      description:
        'Check out the latest streams & interviews exclusively on MILLIONS',
      buttonText: 'Watch Now',
      displayMode: 'carousel',
      variant: 'primary',
      link: STREAMS,
    },
  ];
};

export const getSlideContent = (slideNumber: number): SlideContent => {
  const envLink = getEnvLink();

  switch (slideNumber) {
    case 1:
      return {
        videoSrc: `${envLink}/home-page/TV_opener_main.mp4`, // TODO For demo purposes switch to CDN
        title: 'Stream Your Events Globally\n & Reach MILLIONS',
        description:
          'Create an account and launch your event in minutes. Stream on\n MILLIONS or through RTMP. Add participants, use promo codes, and\n get content marketing support. MILLIONS offers turnkey streaming &\n event marketing. Free stream or PPV options available.',
        overlayAlign: 'right',
        buttonLabel: 'Learn More About Events',
        link: EVENTS,
      };
    case 2:
      return {
        title: 'Power Your Brand with\n Talent Campaigns',
        image: `${getEnvLink()}/images/home-page/campaign-manager-bg.png`,
        description:
          'Seamlessly connect with MILLIONS athletes, creators, and events to\n launch impactful campaigns. Set goals, manage collaborations, and\n track performance—all in one place.',
        overlayAlign: 'left',
        buttonLabel: 'Start With Campaign Manager',
        link: ADVERTISE,
      };
    case 3:
      return {
        videoSrc: `${envLink}/home-page/Section-3-Hosts.mp4`, // TODO For demo purposes switch to CDN
        title: 'Be The Voice Of The Sports\n Community',
        description:
          'Take the stage and host exclusive interviews, live streams,\n podcasts, and fan Q&As. Share your passion, connect with brands, and grow\n your audience.',
        overlayAlign: 'right',
        buttonLabel: 'Learn More About Creators',
        link: CREATOR,
      };
    case 4:
      return {
        videoSrc: `${envLink}/home-page/Section-4-ecommerce.mp4`, // TODO For demo purposes switch to CDN
        title: 'Create, Sell, Celebrate &\n Earn With MILLIONS Shop',
        description:
          "Don't just build your channel, build your brand. Your MILLIONS shop\n is an easy all-in-one solution to have custom merch, sell memorabilia,\n custom products, and experiences that your fans will love.",
        overlayAlign: 'left',
        buttonLabel: 'Learn More About Shop',
        link: INFO,
      };
    default:
      throw new Error(`Invalid slide number: ${slideNumber}`);
  }
};
