import React from 'react';
// Components
import { DownloadApp } from 'components/common3/DownloadApp/DownloadApp';
// Helpers
import { getEnvLink } from 'helpers/routes';
// Styles
import styles from './DownloadAppSection.module.scss';

interface DownloadAppSectionProps {
  backgroundImageUrl?: string;
  phoneImageUrl?: string;
}

const DownloadAppSection: React.FC<DownloadAppSectionProps> = ({
  backgroundImageUrl,
  phoneImageUrl,
}) => {
  const imagePath = backgroundImageUrl
    ? backgroundImageUrl
    : `${getEnvLink()}/images/home-page/Background.png`;

  const phoneImage = phoneImageUrl
    ? phoneImageUrl
    : `${getEnvLink()}/images/home-page/phone.png`;
  return (
    <section
      className={styles.downloadAppSection}
      style={{ backgroundImage: `url(${imagePath})` }}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{'Download the \napp'}</h2>
        <p className={styles.subtitle}>
          {
            'Ready to engage with the sports community? Download the app for\n the full experience.'
          }
        </p>
        <div className={styles.storeButtons}>
          <DownloadApp />
        </div>
      </div>

      <div className={styles.phoneContainer}>
        <img
          className={styles.phoneImage}
          src={phoneImage}
          alt="App Preview on Phone"
        />
      </div>
    </section>
  );
};

export default DownloadAppSection;
