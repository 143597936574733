import React from 'react';
import styles from './GetPhonesCta.module.scss';
import { DownloadApp } from 'components/common3/DownloadApp/DownloadApp';
import { getEnvLink } from 'helpers/routes';

interface AppDownloadSectionProps {
  title?: string;
  description?: string;
}

const PhoneAppDownloadCta: React.FC<AppDownloadSectionProps> = ({
  title = 'Get The App Now!',
  description = 'Download the MILLIONS mobile app to access the full experience. \nAvailable on iOS and Android.',
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <div className={styles.storeLinks}>
          <DownloadApp />
        </div>

        <div className={styles.appScreenshots}>
          <img
            src={`${getEnvLink()}/images/home-page/phone-screenshots/phone-2.png`}
            alt="App screenshot 5"
            className={styles.screenshot}
          />
          <img
            src={`${getEnvLink()}/images/home-page/phone-screenshots/phone-5.png`}
            alt="App screenshot 4"
            className={styles.screenshot}
          />
          <img
            src={`${getEnvLink()}/images/home-page/phone-screenshots/phone-3.png`}
            alt="App screenshot 2"
            className={styles.screenshot}
          />

          <img
            src={`${getEnvLink()}/images/home-page/phone-screenshots/phone-4.png`}
            alt="App screenshot 3"
            className={styles.screenshot}
          />
          <img
            src={`${getEnvLink()}/images/home-page/phone-screenshots/phone-1.png`}
            alt="App screenshot 1"
            className={styles.screenshot}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneAppDownloadCta;
