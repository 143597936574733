import React, { useEffect, useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
// Components
import Heroshot from './Heroshot/Heroshot';
import MainPageContentSection from './EventsCta/VideoBackgroundSection';
import DownloadAppSection from './GetAppCta/GetApp/DownloadAppSection';
import PhoneAppDownloadCta from './GetAppCta/GetPhonesCta/GetPhonesCta';
import ImageGridCarousel from './ImageGridCarousel/ImageGridCarousel';
import CardLayout from './MainCards/MainCardsLayout/MainCardsLayout';
// Layouts
import Footer from 'layouts/Footer/Footer';
// Helpers
import { getSlideContent } from './helpers/SlideHelper';
// Styles
import styles from './HomePage.module.scss';

const DesktopHomePage = ({ banners, cardsData }) => {
  const [cardFactor, setCardFactor] = useState(1);

  useEffect(() => {
    const calculateLayout = () => {
      const viewportWidth = window.innerWidth;
      const isBentoColumn = viewportWidth <= 1024;

      if (isBentoColumn) {
        setCardFactor(2);
      } else {
        setCardFactor(1);
      }
    };

    calculateLayout();
    window.addEventListener('resize', calculateLayout);

    return () => window.removeEventListener('resize', calculateLayout);
  }, []);

  return (
    <Parallax className={styles.parallaxContainer} pages={9}>
      <ParallaxLayer offset={0} speed={0.1} factor={1}>
        <Heroshot banners={banners} />
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.5} factor={cardFactor}>
        <CardLayout cards={cardsData} />
      </ParallaxLayer>

      <ParallaxLayer offset={1 + cardFactor} speed={0.1} factor={1}>
        <PhoneAppDownloadCta />
      </ParallaxLayer>

      <ParallaxLayer
        offset={2 + cardFactor}
        speed={0.2}
        sticky={{ start: 2.1 + cardFactor, end: 3 + cardFactor }}
        factor={1}
      >
        <MainPageContentSection {...getSlideContent(1)} />
      </ParallaxLayer>

      <ParallaxLayer
        offset={3 + cardFactor}
        speed={0.3}
        sticky={{ start: 3 + cardFactor, end: 4 + cardFactor }}
        factor={1}
      >
        <MainPageContentSection {...getSlideContent(2)} />
      </ParallaxLayer>

      <ParallaxLayer
        offset={4 + cardFactor}
        speed={0.4}
        sticky={{ start: 4 + cardFactor, end: 5 + cardFactor }}
        factor={1}
      >
        <MainPageContentSection {...getSlideContent(3)} />
      </ParallaxLayer>

      <ParallaxLayer
        offset={5 + cardFactor}
        speed={0.5}
        sticky={{ start: 5 + cardFactor, end: 6 + cardFactor }}
        factor={1}
      >
        <MainPageContentSection {...getSlideContent(4)} />
      </ParallaxLayer>

      <ParallaxLayer
        offset={6 + cardFactor}
        speed={0.6}
        sticky={{ start: 6 + cardFactor, end: 7 + cardFactor }}
        factor={1}
      >
        <ImageGridCarousel imageCount={16} />
      </ParallaxLayer>

      <ParallaxLayer
        offset={7 + cardFactor}
        speed={0.7}
        factor={1}
        sticky={{ start: 7 + cardFactor, end: 8 }}
      >
        <DownloadAppSection />
        <Footer />
      </ParallaxLayer>
    </Parallax>
  );
};

export default DesktopHomePage;
