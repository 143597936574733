import { gql } from '@apollo/client';
// Fragments
import { AllAmaEntities } from 'api/ama/fragments';

export const GET_ALL_HOME_PAGE_DATA = gql`
  query GetAllHomePageData(
    $upcomingStreamsInput: StreamFilterInput!
    $upcomingStreamsDuplicationInput: StreamFilterInput!
  ) {
    getHomePageData {
      stores {
        orderIndex
        id
        slug
        role
        hasAma
        hasMerch
        hasStreams
        hasProducts
        hasExperiences
        hasMediaPost
        storeDetails {
          id
          storeName
          storeTitle
          avatarURL
        }
        sports {
          id
          name
        }
      }
      amas {
        orderIndex
        ...AllAmaEntities
      }
      merch {
        orderIndex
        id
        status
        title
        slug
        minPrice
        mainImageUrl
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
      watchStreams {
        id
        slug
        name
        imageURL
        price
        description
        isFree
        streamStatus
        scheduleDate
        orderIndex
        timeZone {
          tzCode
          offset
        }
        isPurchased
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
      mainBanners {
        id
        image {
          id
          url
        }
        video {
          id
          url
        }
        title
        content
        link
        shouldLinkRedirect
        buttonContent
      }
      ctaBanners {
        id
        image {
          id
          url
        }
        title
        content
        link
        buttonContent
        shouldLinkRedirect
        isBannerVisible
        linkContent
        buttonLink
      }
      athleteStories {
        id
        title
        description
        imageURL
        link
        publishedDate
        store {
          id
          slug
          firstName
          lastName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
    upcomingStreams: streams(input: $upcomingStreamsInput) {
      entities {
        id
        slug
        name
        imageURL
        price
        description
        isFree
        isHidden
        streamStatus
        scheduleDate
        orderIndex
        timeZone {
          tzCode
          offset
        }
        isPurchased
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
    upcomingStreamsDuplication: streams(
      input: $upcomingStreamsDuplicationInput
    ) {
      entities {
        id
        slug
        name
        imageURL
        price
        description
        isFree
        streamStatus
        scheduleDate
        orderIndex
        timeZone {
          tzCode
          offset
        }
        isPurchased
        store {
          id
          slug
          lastName
          firstName
          storeDetails {
            id
            storeName
          }
        }
      }
    }
  }
  ${AllAmaEntities}
`;
