import React from 'react';
import cn from 'classnames';
// Components
import LinkButton from 'ui3/LinkButton/LinkButton';
// Styles
import styles from './VideoBackgroundSection.module.scss';

interface GetStartedCtaBannerProps {
  videoSrc?: string;
  image?: string;
  description?: string;
  title?: string;
  buttonLabel?: string;
  link: string;
  onButtonClick?: () => void;
  overlayAlign?: 'left' | 'right'; // New prop to control alignment
}

const MainPageContentSection = ({
  videoSrc,
  image,
  title = 'Your Title Here',
  description = 'Your description goes here. This text is positioned in the upper-right corner.',
  overlayAlign = 'right',
  buttonLabel = 'Learn more',
  link,
}: GetStartedCtaBannerProps) => {
  const imagePath = image ? image : '';

  return (
    <div
      className={cn(
        styles.videoContainer,
        image && !videoSrc && styles.withBackgroundImage
      )}
      style={image ? { backgroundImage: `url(${imagePath})` } : {}}
    >
      {videoSrc && (
        <video
          className={styles.backgroundVideo}
          playsInline
          autoPlay
          loop
          muted
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div
        className={cn(
          styles.contentOverlay,
          overlayAlign === 'left' && styles['contentOverlay--left'],
          overlayAlign === 'right' && styles['contentOverlay--right']
        )}
      >
        <div className={styles.textContent}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
          <LinkButton
            link={link}
            className={styles.buttonCustomStyles}
            subVariant="text"
          >
            {buttonLabel}
          </LinkButton>
        </div>
      </div>
    </div>
  );
};

export default MainPageContentSection;
